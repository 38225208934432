<template>
  <div>
    <span v-for="em in emList" :key="em">
      <a href="#" @click="select(em)">{{ em }}</a>
    </span>
  </div>
</template>

<script>

export default {
  name: 'Emoji',
  data: function () {
    return {
      emList: []
    }
  },
  mounted: function () {
    let e = this.emList;
    for (let i = 0x1f32d; i <= 0x1f382; i++) {
      e.push(String.fromCodePoint(i));
    }
    for (let i = 0x1f402; i <= 0x1f43c; i++) {
      e.push(String.fromCodePoint(i));
    }
    for (let i = 0x1f452; i <= 0x1f462; i++) {
      e.push(String.fromCodePoint(i));
    }
    for (let i = 0x1f7e0; i <= 0x1f7eb; i++) {
      e.push(String.fromCodePoint(i));
    }
    for (let i = 0x1f94f; i <= 0x1f96f; i++) {
      e.push(String.fromCodePoint(i));
    }
    for (let i = 0x1f9c0; i <= 0x1f9cb; i++) {
      e.push(String.fromCodePoint(i));
    }
    for (let i = 0x1fad0; i <= 0x1fadb; i++) {
      e.push(String.fromCodePoint(i));
    }
  },
  methods: {
    select: function (em) {
      this.$emit('emoji',em);
    }
  }
};
</script>